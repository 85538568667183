import Head from "./components/head";


function App() {
  return (
    <>
      <Head />
      <a href="https://discord.gg/U5fT2xcnq3" className="text-4xl font-semibold text-slate-900">website will be finished soon! join my discord pls lol 👍</a>
    </>
  );
}

export default App;
